import { map } from 'rxjs/operators'
import UserPreferencesApi from '../UserPreferencesApi'
import Fetch from '../fetch'

export interface ICartItem {
    created_at?: string
    updated_at?: string
    cart_item_type: string
    product_id: string
    billing_option_id: string
    campaign_id?: number
}
export interface IShoppingCart {
    created_at?: string
    updated_at?: string
    cart_items: ICartItem[]
}
export default class ShoppingCartApi extends Fetch {
    /**
     * This method ADD the item to the cart.
     */

    addItemToCart = (shoppingCart: IShoppingCart) => {
        const now = Date.now().toString()
        shoppingCart.created_at = shoppingCart.created_at || now
        shoppingCart.updated_at = now

        shoppingCart.cart_items.map((item) => {
            item.created_at = shoppingCart.created_at || now
            item.updated_at = shoppingCart.updated_at || now
            return item
        })

        return this.userPreferencesApi
            .addUserPreference(UserPreferencesApi.USER_PREFERENCES.SHOPPING_CART, {
                [UserPreferencesApi.USER_PREFERENCES.SHOPPING_CART]: shoppingCart,
            })
            .pipe(map((response) => response))
    }

    getShoppingCart = () =>
        this.userPreferencesApi
            .getUserPreference(UserPreferencesApi.USER_PREFERENCES.SHOPPING_CART)
            .pipe(map((response) => response))

    deleteShoppingCart = () =>
        this.userPreferencesApi
            .deleteUserPreferenceAndRefresh(UserPreferencesApi.USER_PREFERENCES.SHOPPING_CART)
            .pipe(map((response) => response))

    updateShoppingCart = () => {
        this.logger('Update Cart PlaceHolder')
    }
}
