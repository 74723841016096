const production = {
    env: 'production',
    supportedLanguages: ['en'],
    carePortal: {
        urls: {
            adminAuthUrl: 'https://attus02.ulm.stg.m1amdocs.io',
        },
        agentClientId: 'HaloCTestRP',
        ssoClientId: 'haloeFederationClientID',
        ssoClientSecret: 'secret0.5477682322858153',
        OAuthParam: {
            extras: {},
        },
    },
    urls: {
        apiUrl: 'https://h3git-ulm-pdt.m1amdocs.io/platform/rest/v85',
        authUrl: 'https://h3git-ulm-pdt.m1amdocs.io',
        userPreferencesUrl: 'https://h3git-ulm-pdt.m1amdocs.io/customData',
        storefrontUrl: 'https://h3git-ulm-pdt.m1amdocs.io/storefront',
        emi: {
            baseUrl: 'https://waf-h3git-metadata-api-pre001.ifs.vubiquity.com/emi',
            homePath: '3.0/{population}/1',
            defaultPopulation: 'pdt',
        },
        proxyUrl: 'https://waf-h3git-metadata-api-pre001.ifs.vubiquity.com/maculosa/v3.1',
    },
    clientId: 'a04aa6c77c7c409884f8fd10c0cc684c',
    OAuthParam: {
        scope: 'openid profile storefront customData',
        grantType: 'authorization_code',
        requestMode: 'form_post',
        endpoint: {
            auth: '/oidc/authorize',
            token: '/oidc/token',
            refreshToken: '/oauth/token',
        },
        extras: {
            response_mode: 'form_post',
        },
        debugMode: true,
        forcePollingIntervalSecs: 10,
    },
    proxyVersion: 3,
    encryption: {
        enabled: true,
        passphrase: 'mY5}vH9%pN0!dW7{xI7$aG8{iK8}aT2/',
    },
    marketone: {
        skipRefreshToken: true,
        enableUserPreferencesApi: true,
        apiVersion: 'V2',
        retrieveOnlyActiveSubscriptions: false,
    },
    firebaseConfig: {
        enabled: false,
        apiKey: '',
        authDomain: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        appId: '',
        measurementId: '',
    },
    authenticatedRoutes: ['root-perks'],
    unauthenticatedRoutes: ['/root-discovery'],
}

export default production
