import {useContext} from 'solid-js'
import {i18nProviderContext} from './i18nProviderContext'
import {defineMessages} from '@formatjs/intl'
import {locales} from 'client-translations'
import {useLocale} from './useLocale'
import {useConfig} from '../components'

export const useI18n = () => {
    const context = useContext(i18nProviderContext)
    const locale = useLocale()

    return {
        defineMessages: (messages: any) => defineMessages(messages),
        formatMessage: (id: any, values?: Record<string, any>) => context?.formatMessage(id, values),
        formatCurrency: (value: number, options?: any) =>
            context?.formatNumber(value || 0, {style: 'currency', ...options}),
        formatNumber: (value: number, options?: any) => context?.formatNumber(value, options),
        formatString: (stringKey: string) => {
            return locales[locale()]?.messages?.[stringKey]
        },
        formatDate: (dateString: string): string => {
            const date = new Date(dateString)
            return date.toLocaleDateString('en-US')
        },
        formatDateLongType: (dateString: string): string => {
            const date = new Date(dateString)
            const month = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ]
            const monthName = month[date.getMonth()]
            const day = date.getDate()
            const year = date.getFullYear()
            const longDate = monthName + ' ' + day + ',' + ' ' + year
            return longDate
        },
        isPastDate: oldDate => {
            return new Date(oldDate).getTime() < Date.now()
        },
    }
}
